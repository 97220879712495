import React from 'react'
import PropTypes from 'prop-types'
import Helmet from 'react-helmet'
import { useStaticQuery, graphql } from 'gatsby'

const SEO = ({
  path,
  description,
  lang,
  meta,
  keywords,
  title,
  isArticle = false,
}) => {
  const data = useStaticQuery(graphql`
    query DefaultSEOQuery {
      site {
        siteMetadata {
          title
          description
          author,
          siteUrl
        }
      }
    }
  `)

  const {
    title: siteTitle,
    description: siteDescription,
    author,
    siteUrl,
  } = data.site.siteMetadata

  const metaTitle = title || siteTitle
  const metaDescription = description || siteDescription

  return (
    <Helmet
      defer={false}
      htmlAttributes={{ lang }}
      title={metaTitle}
      titleTemplate={title ? `${title} // ${siteTitle}` : siteTitle}
      link={path ? [
        {
          rel: 'canonical',
          href: `${siteUrl}${path}`,
        },
      ] : []}
      meta={[
        {
          name: 'description',
          content: metaDescription,
        },
        {
          property: 'og:title',
          content: metaTitle,
        },
        {
          property: 'og:description',
          content: metaDescription,
        },
        {
          property: 'og:type',
          content: isArticle ? 'article' : 'website',
        },
        {
          name: 'twitter:card',
          content: 'summary',
        },
        {
          name: 'twitter:title',
          content: metaTitle,
        },
        {
          name: 'twitter:description',
          content: metaDescription,
        },
        {
          name: 'twitter:creator',
          content: author,
        },
      ]
        .concat(keywords.length > 0 ?
          {
            name: 'keywords',
            content: keywords.join(', '),
          } : [])
        .concat(meta)}
    />
  )
}

SEO.defaultProps = {
  lang: 'en',
  meta: [],
  keywords: [],
}

SEO.propTypes = {
  path: PropTypes.string,
  description: PropTypes.string,
  lang: PropTypes.string,
  meta: PropTypes.array,
  keywords: PropTypes.arrayOf(PropTypes.string),
  title: PropTypes.string,
  isArticle: PropTypes.bool,
}

export default SEO
