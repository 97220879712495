// extracted by mini-css-extract-plugin
export const mobileMenuContainer = "menu-module--mobileMenuContainer--1sq3i";
export const desktopMenuContainer = "menu-module--desktopMenuContainer--1b1e3";
export const menu = "menu-module--menu--3ukrn";
export const menuTrigger = "menu-module--menuTrigger--6orQW";
export const mobileMenu = "menu-module--mobileMenu--2ml_g";
export const mobileMenuOverlay = "menu-module--mobileMenuOverlay--TaEc3";
export const themeToggle = "menu-module--themeToggle--2_FQ_";
export const subMenuTrigger = "menu-module--subMenuTrigger--1068I";
export const subMenu = "menu-module--subMenu--1fFpT";
export const subMenuOverlay = "menu-module--subMenuOverlay--aQ54c";
export const menuArrow = "menu-module--menuArrow--3s2c9";