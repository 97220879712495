import React from 'react'
import PropTypes from 'prop-types'

const Footer = ({ copyrights }) => (
  <footer>
    {copyrights ? (
      // eslint-disable-next-line react/no-danger
      <div dangerouslySetInnerHTML={{
        __html: copyrights,
      }}
      />
    ) : (
      <>
        <span className="footer__copyright">
          &copy; 2021 Built with <a href="https://www.gatsbyjs.org">Gatsby</a>
        </span>
      </>
    )}
  </footer>
)

Footer.propTypes = {
  copyrights: PropTypes.string,
}

export default Footer
